<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960"><path d="M498-334.85q-34 .62-65.88-8.92-31.89-9.54-55.35-33-20.77-20.77-31.85-47.11-11.07-26.35-11.07-55.5 0-11.54 1.38-22.2 1.38-10.65 5.15-21.19 3.23-9.69-.5-19.19-3.73-9.5-12.42-13.35-9.31-3.84-18.5.19-9.19 4.04-12.42 13.74-5 15-7.69 30.19-2.7 15.19-2.7 31.19 0 38.85 14.93 74.19 14.92 35.35 42.15 62.58 28.92 29.92 68.69 43 39.77 13.08 81.47 13.69l-27.77 27.77q-7.08 7.08-7.27 16.58-.2 9.5 7.27 16.96 7.46 7.46 16.76 7.46 9.31 0 16.77-7.46l62.46-62.46q10.85-10.85 10.85-25.31 0-14.46-10.85-25.31l-62.46-62.46q-7.07-7.08-16.57-7.27-9.5-.19-16.96 7.27-7.47 7.46-7.47 16.77 0 9.31 7.47 16.77L498-334.85Zm-36.23-290.92q34 0 66.19 9.54t55.66 33q20.77 20.77 31.84 47.11 11.08 26.35 11.08 55.5 0 11.54-1.39 22.2-1.38 10.65-5.15 21.19-3.23 9.69.5 19.5t12.42 13.65q9.31 3.85 18.5 0 9.19-3.84 12.43-13.54 5-15 7.69-30.69 2.69-15.69 2.69-31.69 0-38.85-14.31-74.19-14.31-35.35-42.15-63.19-29.54-29.93-69.31-42.7-39.77-12.77-81.46-12.77l28.38-28.38q6.47-7.08 6.66-16.58.19-9.5-7.27-16.96-7.46-7.46-16.77-7.46-9.31 0-16.77 7.46l-62.46 62.46q-10.85 10.85-10.85 25.31 0 14.46 10.85 25.31l62.46 62.46q7.08 7.08 16.58 7.27 9.5.19 16.96-7.27 7.46-7.46 7.46-16.77 0-9.31-7.46-16.77l-33-33ZM480-100q-78.77 0-148.11-29.96-69.35-29.96-120.66-81.27-51.31-51.31-81.27-120.66Q100-401.23 100-480q0-78.77 29.96-148.11 29.96-69.35 81.27-120.66 51.31-51.31 120.66-81.27Q401.23-860 480-860q78.77 0 148.11 29.96 69.35 29.96 120.66 81.27 51.31 51.31 81.27 120.66Q860-558.77 860-480q0 78.77-29.96 148.11-29.96 69.35-81.27 120.66-51.31 51.31-120.66 81.27Q558.77-100 480-100Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>