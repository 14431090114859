<template>
    <div class="pong-container">
      <div class="difficulty-dropdown">
        <select v-model="selectedDifficulty" @change="setDifficulty">
          <option value="easy">Easy</option>
          <option value="medium">Medium</option>
          <option value="hard">Hard</option>
        </select>
      </div>
      <div v-if="isMobileLandscape && !gameStarted" class="rotate-device-message">
        <p>Please rotate your device to landscape orientation to play the game.</p>
      </div>
      <div class="start-button-container" v-if="!gameStarted && (!isMobileLandscape || !rotateDevicePromptVisible)">
        <button @click="startGame">Start</button>
      </div>
      <div class="pong-game"
           v-show="gameStarted && !isMobileLandscape"
           @mousemove="movePaddle"
           @touchmove.prevent="movePaddleMobile">
        <div class="paddle player" :style="{ left: paddle1X + 'px', top: paddle1Y + 'px' }"></div>
        <div class="paddle computer" :style="{ left: paddle2X + 'px', top: paddle2Y + 'px' }"></div>
        <div class="ball" :style="{ left: ballX + 'px', top: ballY + 'px' }"></div>
      </div>
      <div class="scoreboard" v-show="gameStarted && !isMobileLandscape">Score: {{ playerScore }}</div>
      <div v-if="gameOver" class="game-over-popup">
        <div>Game Over!</div>
        <button @click="restartGame">Restart</button>
      </div>
      <div class="control-buttons">
        <button class="control-button" @click="togglePause">{{ gamePaused ? 'Play' : 'Pause' }}</button>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  
  const paddleWidth = 10;
  const paddleHeight = 80;
  const ballSize = 10;
  const gameWidth = window.innerWidth * 0.7;
  const gameHeight = window.innerHeight * 0.7;
  
  const paddle1X = ref(10);
  const paddle1Y = ref(gameHeight / 2 - paddleHeight / 2);
  const paddle2X = ref(gameWidth - paddleWidth - 10);
  const paddle2Y = ref(gameHeight - paddleHeight);
  
  const ballX = ref(gameWidth / 2);
  const ballY = ref(gameHeight / 2);
  let ballSpeedX = ref(6);
  let ballSpeedY = ref(6);
  const maxBallSpeed = 12;
  
  const playerScore = ref(0);
  const computerScore = ref(0);
  const gameOver = ref(false);
  const gameStarted = ref(false);
  const rotateDevicePromptVisible = ref(true);
  const gamePaused = ref(false);
  
  let selectedDifficulty = ref('medium');
  
  const togglePause = () => {
    gamePaused.value = !gamePaused.value;
  };
  
  const moveBall = () => {
    if (!gameOver.value && gameStarted.value && !gamePaused.value) {
      ballX.value += ballSpeedX.value;
      ballY.value += ballSpeedY.value;
  
      // Ball collision with top and bottom walls
      if (ballY.value <= 0 || ballY.value >= gameHeight - ballSize) {
        ballSpeedY.value *= -1;
      }
  
      // Ball collision with paddles
      if (
        (ballX.value <= paddle1X.value + paddleWidth && ballY.value >= paddle1Y.value && ballY.value <= paddle1Y.value + paddleHeight) ||
        (ballX.value >= paddle2X.value - ballSize && ballY.value >= paddle2Y.value && ballY.value <= paddle2Y.value + paddleHeight)
      ) {
        ballSpeedX.value *= -1;
        if (ballX.value <= paddle1X.value + paddleWidth) {
          playerScore.value++;
          if (ballSpeedX.value < maxBallSpeed) {
            ballSpeedX.value += 0.2;
          }
          if (ballSpeedY.value < maxBallSpeed) {
            ballSpeedY.value += 0.2;
          }
        }
      }
  
      // Ball out of bounds (game over)
      if (ballX.value <= 0 || ballX.value >= gameWidth) {
        gameOver.value = true;
      }
    }
  };
  
  const resetGame = () => {
    playerScore.value = 0;
    computerScore.value = 0;
    gameOver.value = false;
    gameStarted.value = false;
    resetBall();
  };
  
  const resetBall = () => {
    ballX.value = gameWidth / 2;
    ballY.value = gameHeight / 2;
    if (selectedDifficulty.value === 'easy') {
      ballSpeedX.value = 4;
      ballSpeedY.value = 4;
    } else if (selectedDifficulty.value === 'medium') {
      ballSpeedX.value = 5;
      ballSpeedY.value = 5;
    } else if (selectedDifficulty.value === 'hard') {
      ballSpeedX.value = 6;
      ballSpeedY.value = 6;
    }
  };
  
  const restartGame = () => {
    resetGame();
    startGame(); // Start the game immediately after restart
  };
  
  const startGame = () => {
    gameStarted.value = true;
    rotateDevicePromptVisible.value = false;
  };
  
  const setDifficulty = () => {
    resetBall();
  };
  
  onMounted(() => {
    setInterval(moveBall, 16); // Move ball every 16ms (60fps)
    setInterval(moveComputerPaddle, 5); // Move computer paddle every 5ms
  });
  
  const movePaddle = (event) => {
    const mouseY = event.clientY - event.target.getBoundingClientRect().top;
    paddle1Y.value = Math.min(gameHeight - paddleHeight, Math.max(0, mouseY - paddleHeight / 2));
  };
  
  const movePaddleMobile = (event) => {
    const touch = event.touches[0];
    if (touch) {
      const mouseY = touch.clientY - event.target.getBoundingClientRect().top;
      paddle1Y.value = Math.min(gameHeight - paddleHeight, Math.max(0, mouseY - paddleHeight / 2));
    }
  };
  
  const moveComputerPaddle = () => {
    const paddleCenter = paddle2Y.value + paddleHeight / 2;
    const ballCenter = ballY.value + ballSize / 2;
  
    if (ballX.value > gameWidth / 2) {
      if (paddleCenter < ballCenter - 10) {
        paddle2Y.value += Math.min(2, ballCenter - paddleCenter - 10);
      } else if (paddleCenter > ballCenter + 10) {
        paddle2Y.value -= Math.min(2, paddleCenter - ballCenter - 10);
      }
    }
  };
  
  const isMobileLandscape = computed(() => {
    return window.innerWidth < window.innerHeight;
  });
  </script>
  
  <style scoped>
  .pong-container {
    position: relative;
    width: 70vw;
    height: 70vh;
    margin: 0 auto;
  }
  
  .difficulty-dropdown {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .difficulty-dropdown select {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
  }
  
  .scoreboard {
    position: absolute;
    top: 10px;
    right: 10px;
    color: white;
    font-size: 20px;
    z-index: 1;
  }
  
  .pong-game {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #333;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  }
  
  .paddle {
    position: absolute;
    width: 10px;
    height: 80px;
  }
  
  .player {
    background-color: #6dd5ed;
  }
  
  .computer {
    background-color: #f57b51;
  }
  
  .ball {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #f7dc5c;
    border-radius: 50%;
  }
  
  .game-over-popup {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .game-over-popup button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #6dd5ed;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .game-over-popup button:hover {
    background-color: #5ab8d3;
  }
  
  .rotate-device-message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
  }
  
  .control-buttons {
    position: absolute;
    top: 10px;
    left: 10px;
  }
  
  .control-button {
    padding: 8px 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
  }
  
  .control-button:hover {
    background-color: #f0f0f0;
  }
  
  .start-button-container {
    position: absolute;
    top: calc(50% + 40px);
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .start-button-container button {
    padding: 10px 20px;
    background-color: #6dd5ed;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 16px;
    cursor: pointer;
  }
  
  .start-button-container button:hover {
    background-color: #5ab8d3;
  }
  
  @media (max-width: 768px) {
    .pong-container {
      width: 90vw;
      height: 90vh;
    }
  }
  </style>
  