<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960"><path d="M461.54-443.08h-44.92q-9.85 0-15.08-8.54-5.23-8.53-1-17.76l80.84-168.77q3.24-6.23 10.16-4.73t6.92 8.73v117.23h44.92q9.85 0 15.08 8.54 5.23 8.53 1 17.76l-80.84 168.77q-3.24 6.23-10.16 4.73t-6.92-8.73v-117.23ZM292.31-60Q262-60 241-81q-21-21-21-51.31v-695.38Q220-858 241-879q21-21 51.31-21h375.38Q698-900 719-879q21 21 21 51.31v695.38Q740-102 719-81q-21 21-51.31 21H292.31ZM280-230h400v-500H280v500Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>