<template>
    <div class="popup">
        <div class="overlay"></div>
        <div class="box">
            <div class="title">
                Tasks
                <div class="close" @click="close()">
                    <close_icon :size="'30px'"/>
                </div>
            </div>
            <div class="content">

                <div>
                    <div class="task" @click="open_link('x')">
                        <div class="icon">
                            <x_icon :size="'25px'"/>
                        </div>
                        <div>
                            <div class="text">Follow us on X</div>
                            <div class="reward">
                                <div class="coin">
                                    <img src="@/assets/coin2.png"/>
                                </div>
                                <div class="no">500</div>
                            </div>
                        </div>

                        <div class="left" v-if="store.follow_claimed">
                            <div class="arrow" >
                                <check_icon :size="'20px'"/>
                            </div>
                        </div>
                        <div class="left" v-else>
                            <loading_vue v-if="tasks['x']['status'] == 'loading'"/>

                            <div class="arrow" v-if="tasks['x']['status'] == ''">
                                <arrow_icon :size="'20px'"/>
                            </div>

                        </div>
                    </div>
                    <div class="bottom" v-if="tasks['x']['claim']">
                        <div class="loading" v-if="tasks['x']['status'] == 'claim_loading'">
                            <loading_vue/>
                        </div>
                        <div class="btn" v-if="tasks['x']['status'] == 'claim'" @click="claim_reward('x')">Claim</div>
                    </div>
                </div>

                <div>
                    <div class="task" @click="open_link('st')">
                        <div class="icon">
                            <telegram_icon :size="'25px'"/>
                        </div>
                        <div>
                            <div class="text">Subcribe on Telegram</div>
                            <div class="reward">
                                <div class="coin">
                                    <img src="@/assets/coin2.png"/>
                                </div>
                                <div class="no">500</div>
                            </div>
                        </div>

                        <div class="left" v-if="store.channel_claimed">
                            <div class="arrow" >
                                <check_icon :size="'20px'"/>
                            </div>
                        </div>
                        <div class="left" v-else>
                            <loading_vue v-if="tasks['st']['status'] == 'loading'"/>

                            <div class="arrow" v-if="tasks['st']['status'] == ''">
                                <arrow_icon :size="'20px'"/>
                            </div>
                        </div>
                    </div>
                    <div class="bottom" v-if="tasks['st']['claim']">
                        <div class="loading" v-if="tasks['st']['status'] == 'claim_loading'">
                            <loading_vue/>
                        </div>
                        <div class="btn" v-if="tasks['st']['status'] == 'claim'" @click="claim_reward('st')">Claim</div>
                    </div>
                </div>

                <div>
                    <div class="task" @click="open_link('tg')">
                        <div class="icon">
                            <telegram_icon :size="'25px'"/>
                        </div>
                        <div>
                            <div class="text">Join Telegram Group</div>
                            <div class="reward">
                                <div class="coin">
                                    <img src="@/assets/coin2.png"/>
                                </div>
                                <div class="no">500</div>
                            </div>
                        </div>
                        
                        <div class="left" v-if="store.group_claimed">
                            <div class="arrow" >
                                <check_icon :size="'20px'"/>
                            </div>
                        </div>
                        <div class="left" v-else>
                            <loading_vue v-if="tasks['tg']['status'] == 'loading'"/>
                            <div class="arrow" v-if="tasks['tg']['status'] == ''">
                                <arrow_icon :size="'20px'"/>
                            </div>
                        </div>
                    </div>
                    <div class="bottom" v-if="tasks['tg']['claim']">
                        <div class="loading" v-if="tasks['tg']['status'] == 'claim_loading'">
                            <loading_vue/>
                        </div>
                        <div class="btn" v-if="tasks['tg']['status'] == 'claim'" @click="claim_reward('tg')">Claim</div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script setup>
import close_icon from '@/icons/close.vue';

import x_icon from '@/icons/x.vue';
import telegram_icon from '@/icons/telegram.vue';

import arrow_icon from '@/icons/arrow.vue';
import check_icon from '@/icons/check.vue'

import loading_vue from '../loading.vue';


import { defineEmits, inject, ref } from 'vue';

import { store } from '@/store/store';

const toast = inject('toast')
const axios = inject('axios')

const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}

const tasks = ref({
    'x': {
        'url': 'https://x.com/YunqiCardz',
        'status': '',
        'claim': false,
        'store': 'follow_claimed',
        'req_url': '/user/task/xfollow',
        'reward': 500
    },
    'st': {
        'url': 'https://t.me/YunqiCardz',
        'status': '',
        'claim': false,
        'store': 'channel_claimed',
        'req_url': '/user/task/tg-channel',
        'reward': 500
    },
    'tg': {
        'url': 'https://t.me/YunqiCardzChat',
        'status': '',
        'claim': false,
        'store': 'group_claimed',
        'req_url': '/user/task/tg-group',
        'reward': 500
    },
})


const open_link = (task) => {
    window.open(tasks.value[task]['url'], '_newtab')

    if (store[tasks.value[task]['store']]) { return }

    tasks.value[task]['status'] = 'loading'

    setTimeout(() => {
        tasks.value[task]['claim'] = true
        tasks.value[task]['status'] = 'claim'
    }, 8000);
}

const claim_reward = (task) => {

    tasks.value[task]['status'] = 'claim_loading'

    axios.post(tasks.value[task]['req_url'],{
        user_id: store.user_id
    })
    .then(data => {
        let r = data.data
        if (r.status == 'ok') {
            
            tasks.value[task]['claim'] = false
            tasks.value[task]['status'] = ''
            
            toast.show("Reward Claimed")
            store[tasks.value[task]['store']] = true

            store.points += tasks.value[task]['reward']
            store.total_points += tasks.value[task]['reward']
        }
    })
}


</script>

<style scoped>

.popup {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.2);
}
.box {
    position: relative;

    width: 100%;
    margin: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    
    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 10px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 600;
    text-align: center;
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}
.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.content {
    background-color: #321a02;
    outline: 3px solid #fff;
    padding: 20px 0px;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}


.task {
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px 20px;
    fill: white;
    /* border: 1px solid #ddd; */
}
.icon {
}
.text {
    font-weight: 700;
    /* font-size: 18px; */
    margin-bottom: 3px;
}
.reward {
    display: flex;
    align-items: center;
    gap: 8px;
}
.coin img {
    width: 20px;
}
.no {
    font-size: 14px;
    line-height: 22px;
}

.left {
    margin-left: auto;
}

.bottom {
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px 20px;
    border-top: 1px solid #ddd;
}
.btn {
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 12px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
}

.loading {
    width: fit-content;
    margin: 0 auto;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
}
</style>