<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960"><path d="M322.33-289.23q15.05 0 25.21-10.18 10.15-10.18 10.15-25.23t-10.18-25.21Q337.33-360 322.28-360t-25.2 10.18q-10.16 10.18-10.16 25.23t10.18 25.2q10.19 10.16 25.23 10.16Zm0-155.39q15.05 0 25.21-10.18 10.15-10.18 10.15-25.23t-10.18-25.2q-10.18-10.15-25.23-10.15t-25.2 10.18q-10.16 10.18-10.16 25.23t10.18 25.2q10.19 10.15 25.23 10.15Zm0-155.38q15.05 0 25.21-10.18 10.15-10.18 10.15-25.23t-10.18-25.2q-10.18-10.16-25.23-10.16t-25.2 10.18q-10.16 10.18-10.16 25.23t10.18 25.21Q307.29-600 322.33-600Zm151.52 305.38h167.69q12.75 0 21.37-8.63 8.63-8.62 8.63-21.38 0-12.75-8.63-21.37-8.62-8.61-21.37-8.61H473.85q-12.75 0-21.38 8.62-8.62 8.63-8.62 21.39 0 12.75 8.62 21.37 8.63 8.61 21.38 8.61Zm0-155.38h167.69q12.75 0 21.37-8.63 8.63-8.63 8.63-21.38 0-12.76-8.63-21.37-8.62-8.62-21.37-8.62H473.85q-12.75 0-21.38 8.63-8.62 8.63-8.62 21.38 0 12.76 8.62 21.37 8.63 8.62 21.38 8.62Zm0-155.39h167.69q12.75 0 21.37-8.62 8.63-8.63 8.63-21.39 0-12.75-8.63-21.37-8.62-8.61-21.37-8.61H473.85q-12.75 0-21.38 8.63-8.62 8.62-8.62 21.38 0 12.75 8.62 21.37 8.63 8.61 21.38 8.61ZM212.31-140Q182-140 161-161q-21-21-21-51.31v-535.38Q140-778 161-799q21-21 51.31-21h535.38Q778-820 799-799q21 21 21 51.31v535.38Q820-182 799-161q-21 21-51.31 21H212.31Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>