import { reactive } from "vue";

export const store = reactive({


    

    loaded: false,
    updates: 0,
    last_sent_update: 0,
    sending: false,
    last_sent: null,
    
    user_id: '',
    telegram_id: '',
    firstname: '',
    wallet: '',
    total_points: 1000,
    points: 1000,
    balance: 100,
    nfts: 0,

    mining: false,
    mining_started: '',
    last_claimed: '',
    
    referrals: 0,
    
    energy_limit: 1000,
    energy_limit_level: 1,
    energy_balance: 1000,
    recharge_speed: 1,
    multitap: 1,

    follow_claimed: false,
    channel_claimed: false,
    group_claimed: false,
    
    retweet_link: '',
    
    daily_login_count: 0,
    daily_login_last: '',
    today: '',

    

    multitap_upgrades: [
        0, 1000, 2000, 3000, 4000, 5000, 6000, 8000, 9000, 10000
    ],
    recharge_speed_upgrades: [
        0, 2000, 4000, 6000, 8000, 10000, 12000, 14000, 16000, 18000
    ],
    energy_limit_upgrades:[
        {
            'coin': 0,
            'limit': 1000
        },
        {
            'coin': 1000,
            'limit': 2000
        },
        {
            'coin': 2000,
            'limit': 3000
        },
        {
            'coin': 3000,
            'limit': 5000
        },
        {
            'coin': 4000,
            'limit': 10000
        },
        {
            'coin': 5000,
            'limit': 15000
        },
        {
            'coin': 6000,
            'limit': 20000
        },
        {
            'coin': 7000,
            'limit': 25000
        },
        {
            'coin': 8000,
            'limit': 30000
        },
        {
            'coin': 9000,
            'limit': 35000
        }
    ],

})