<template>
    <div class="game">
      <div class="game-board">
        <div v-for="(row, rowIndex) in board" :key="rowIndex" class="board-row">
          <div
            v-for="(cell, cellIndex) in row"
            :key="cellIndex"
            class="board-cell"
            @click="handleClick(rowIndex, cellIndex)"
          >
            {{ cell }}
          </div>
        </div>
      </div>
      <div v-if="gameOver">
        <h1>{{ winner }} Wins!</h1>
        <button @click="resetGame">Play Again</button>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    setup() {
      const board = ref([['', '', ''], ['', '', ''], ['', '', '']]);
      const currentPlayer = ref('X');
      const gameOver = ref(false);
      const winner = ref('');
  
      const winningConditions = [
        // Rows
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        // Columns
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        // Diagonals
        [0, 4, 8],
        [2, 4, 6],
      ];
  
      const checkWinner = () => {
        for (let condition of winningConditions) {
          const [a, b, c] = condition;
          if (board.value[a] === board.value[b] && board.value[a] !== '' && board.value[a] === board.value[c]) {
            gameOver.value = true;
            winner.value = board.value[a];
            return;
          }
        }
  
        // Check for tie
        if (board.value.every((row) => row.every((cell) => cell !== ''))) {
          gameOver.value = true;
          winner.value = 'Tie';
        }
      };
  
      const handleClick = (rowIndex, cellIndex) => {
        if (board.value[rowIndex][cellIndex] === '' && !gameOver.value) {
          const updatedBoard = [...board.value];
          updatedBoard[rowIndex][cellIndex] = currentPlayer.value;
          board.value = updatedBoard;
          currentPlayer.value = currentPlayer.value === 'X' ? 'O' : 'X';
          checkWinner();
        }
      };
  
      const resetGame = () => {
        board.value = [['', '', ''], ['', '', ''], ['', '', '']];
        currentPlayer.value = 'X';
        gameOver.value = false;
        winner.value = '';
      };
  
      return { board, currentPlayer, gameOver, winner, handleClick, resetGame };
    },
  };
  </script>
  
  <style scoped>
  .game {
    text-align: center;
    margin: 50px auto;
  }
  
  .game-board {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
  
  .board-row {
    display: flex;
  }
  
  .board-cell {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2em;
    border: 1px solid #ddd;
    cursor: pointer;
  }
  </style>
  