<template>
    <div class="popup">
        <div class="overlay"></div>
        <div class="box">
            <div class="title">
                Profile
                <div class="close" @click="close()">
                    <close_icon :size="'30px'"/>
                </div>
            </div>
            <div class="content">
                <div class="head">
                    <div class="icon">
                        <user_icon :size="'25px'" :style="{'height': '25px'}"/>
                    </div>
                    <div class="text">{{ store.firstname }}</div>
                </div>
                <div class="flex">
                    <div class="key">Wallet</div>
                    <div class="value" v-if="store.wallet == ''">Link NFT to connect wallet</div>
                    <div class="value" v-else>{{ store.wallet }}</div>
                </div>
                <div class="flex">
                    <div class="key">$YUN Balance</div>
                    <div class="value">{{ store.balance }}</div>
                </div>
                <div class="flex">
                    <div class="key">Points</div>
                    <div class="value">{{ store.points }}</div>
                </div>
                <div class="flex">
                    <div class="key">Total Points Earned</div>
                    <div class="value">{{ store.total_points }}</div>
                </div>
                <div class="flex">
                    <div class="key">Miner</div>
                    <div class="value" v-if="store.mining">Active</div>
                    <div class="value" v-else>Not Active</div>
                </div>
                <div class="flex tt">
                    <div class="key">Tap Tap</div>
                    <div class="ff">
                        <div class="boost">
                            <div class="b">
                                <speed_icon :size="'25px'"/>
                            </div>
                            <div class="no">{{ store.recharge_speed }}</div>
                            <div class="name">Re... Speed</div>
                        </div>
                        <div class="boost">
                            <div class="b">
                                <battery_icon :size="'25px'"/>
                            </div>
                            <div class="no">{{ store.energy_balance }}</div>
                            <div class="name">Energy Limit</div>
                        </div>
                        <div class="boost">
                            <div class="b">
                                <tap_icon :size="'25px'"/>
                            </div>
                            <div class="no">{{ store.multitap }}</div>
                            <div class="name">Multitap</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import close_icon from '@/icons/close.vue';
import user_icon from '@/icons/user.vue';

import speed_icon from '@/icons/speed.vue';
import battery_icon from '@/icons/battery.vue';
import tap_icon from '@/icons/tap.vue';

import { store } from '@/store/store';

import { defineEmits } from 'vue';

const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}


</script>

<style scoped>

.popup {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.2);
}
.box {
    position: relative;

    width: 100%;
    margin: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    
    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 10px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 600;
    text-align: center;
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}
.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.content {
    background-color: #321a02;
    outline: 3px solid #fff;
    padding: 20px 20px;
    border-radius: 10px;

    /* min-height: 50vh; */
    max-height: 70vh;

    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 15px;
}
.head {
    display: flex;
    align-items: center;
    gap: 5px;
    fill: white;
}
.text {
    font-weight: 600;
}


.key {
    font-size: 14px;
    opacity: .6;
}
.value {
    font-weight: 600;
    word-break: break-all;
}

.tt {
}
.tt .key {
    /* opacity: 1; */
    /* margin-bottom: 15px; */
    /* text-align: center; */
    /* display: none; */
}
.ff {
    background-color: rgba(0, 0, 0, 0.5);

    margin: 0px -20px;
    margin-top: 10px;
    padding: 15px 20px;

    fill: white;

    display: flex;
    align-items: center;
    justify-content: space-around;
}
.boost {
    text-align: center;
}
.b {
}
.name {
    opacity: .6;
    font-weight: 500;
    font-size: 12px;
    display: none;
}
.no {
    font-weight: 700;
}
</style>