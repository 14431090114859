<template>
    <div class="body">
        <div id="app">
            <div v-if="!loggedIn">
                <div class="game_name">Tic Tac Toe 4x4</div>
                <div class="game_title">
                    Welcome to the Most advance AI Tic Tac Toe game, get started by entering your EVM wallet address
                </div>
                <input type="text" id="walletAddress" v-model="walletAddress" placeholder="Wallet Address">
                <div class="button blue" @click="login">Login</div>
            </div>
            <div v-else>
                <div class="game_name">Tic Tac Toe 4x4</div>
                <div class="game_title">Most advance AI Tic Tac Toe game, win if you can</div>
                <div class="board">
                    <div v-for="(cell, index) in board" :key="index" class="cell" @click="selectCell(index)" :style="{cursor: cell ? 'default' : 'pointer'}">
                    {{ cell === 'X' ? '❌' : cell === 'O' ? '⭕' : '' }}
                    </div>
                </div>
                <div v-if="winner !== null" class="popup">
                    <p v-if="winner === 'X' || winner === 'O'">Congratulations! {{ winner }} wins!</p>
                    <p v-else>No winner!</p>
                    <div class="button blue" @click="resetGame">Play Again</div>
                </div>
                <div v-else>
                    <div class="turn">{{ currentPlayer == 'O' ? 'AI' : currentPlayer }}'s turn</div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script setup>
    import { ref, onMounted } from 'vue';
    
    const currentPlayer = ref('X');
    const boardSize = 4; // Change board size to 4x4
    const board = ref(Array(boardSize ** 2).fill(null));
    const winner = ref(null);
    const loggedIn = ref(false);
    const walletAddress = ref('');
    const userMoves = ref([]);
    const winningPatterns = ref([]);
    
    const storedWalletAddress = localStorage.getItem('walletAddress');
    if (storedWalletAddress) {
        walletAddress.value = storedWalletAddress;
        loggedIn.value = true;
    }
    
    function login() {
        // EVM compatible wallet address regex
        const evmCompatibleRegex = /^(0x)?[0-9a-fA-F]{40}$/;
        if (evmCompatibleRegex.test(walletAddress.value)) {
            loggedIn.value = true;
            localStorage.setItem('walletAddress', walletAddress.value);
        } else {
            alert('Invalid wallet address. Please enter an EVM compatible wallet address.');
        }
    }
    
    function selectCell(index) {
        if (!board.value[index] && !winner.value) {
            board.value[index] = currentPlayer.value;
            userMoves.value.push(index); // Store user move
        if (checkWin()) {
            // If user wins, store the winning pattern
            winningPatterns.value.push([...userMoves.value]);
            // Reset user moves
            userMoves.value = [];
            winner.value = currentPlayer.value;
        } else if (board.value.every(cell => cell !== null)) {
            // No winner (draw)
            winner.value = 'Draw';
        } else {
            currentPlayer.value = currentPlayer.value === 'X' ? 'O' : 'X';
            if (currentPlayer.value === 'O') {
                setTimeout(makeComputerMove, 500);
            }
        }
        }
    }
    
    function makeComputerMove() {
        let bestMove = -1;
        let bestMoveScore = -Infinity;
    
        for (let i = 0; i < boardSize ** 2; i++) {
        if (!board.value[i]) {
            const score = evaluateMove(i);
            if (score > bestMoveScore) {
                bestMoveScore = score;
                bestMove = i;
            }
        }
        }
    
        selectCell(bestMove);
    }
    
    function evaluateMove(index) {
        const testBoard = [...board.value];
        testBoard[index] = 'O';
    
        if (checkWinForPlayer(testBoard, 'O')) {
            return 10;
        }
    
        testBoard[index] = 'X';
    
        if (checkWinForPlayer(testBoard, 'X')) {
            return 9;
        }
    
        return 0;
    }
    
    function checkWin() {
        const winConditions = [];
        // Rows
        for (let i = 0; i < boardSize; i++) {
            winConditions.push(Array.from({ length: boardSize }, (_, j) => i * boardSize + j));
        }
        // Columns
        for (let j = 0; j < boardSize; j++) {
            winConditions.push(Array.from({ length: boardSize }, (_, i) => i * boardSize + j));
        }
        // Diagonals
        winConditions.push(Array.from({ length: boardSize }, (_, i) => i * (boardSize + 1))); // Top-left to bottom-right
        winConditions.push(Array.from({ length: boardSize }, (_, i) => (i + 1) * (boardSize - 1))); // Top-right to bottom-left
    
        return winConditions.some(condition => {
            return condition.every(cell => board.value[cell] === currentPlayer.value);
        });
    }
    
    function checkWinForPlayer(testBoard, player) {
        const winConditions = [];
        // Rows
        for (let i = 0; i < boardSize; i++) {
            winConditions.push(Array.from({ length: boardSize }, (_, j) => i * boardSize + j));
        }
        // Columns
        for (let j = 0; j < boardSize; j++) {
            winConditions.push(Array.from({ length: boardSize }, (_, i) => i * boardSize + j));
        }
        // Diagonals
        winConditions.push(Array.from({ length: boardSize }, (_, i) => i * (boardSize + 1))); // Top-left to bottom-right
        winConditions.push(Array.from({ length: boardSize }, (_, i) => (i + 1) * (boardSize - 1))); // Top-right to bottom-left
    
        return winConditions.some(condition => {
            return condition.every(cell => testBoard[cell] === player);
        });
    }
    
    function resetGame() {
        currentPlayer.value = 'X';
        board.value = Array(boardSize ** 2).fill(null);
        winner.value = null;
    }
    
    onMounted(resetGame);
</script>
  
<style scoped>
    /* Body styles */
    .body {
        margin: 0;
        padding: 0;
        font-family: Arial, sans-serif;
        background: linear-gradient(to bottom right, royalblue, tomato);
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    
    /* Game container styles */
    #app {
        background-color: rgba(255, 255, 255, 0.8);
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        max-width: 400px;
    }


    .input {
        font-size: 14px;
    }
    input {
        padding: 15px;
        border: 1px solid #ddd;
        border-radius: 5px;
        margin-bottom: 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .game_name {
        font-size: 35px;
        font-weight: 200;
    }
    .game_title {
        margin-bottom: 20px;
        font-size: 14px;
        /* font-weight: 600; */
    }
    
    /* Board styles */
    .board {
        display: grid;
        grid-template-columns: repeat(4, 80px);
        grid-template-rows: repeat(4, 80px);
        gap: 10px;
    }
    
    .cell {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2em;
        border: 2px solid royalblue;
        border-radius: 5px;
        cursor: pointer;
        background-color: white;
        transition: background-color 0.3s ease;
    }
    
    .cell:hover {
        background-color: rgba(135, 206, 250, 0.5);
    }
    
    /* Popup styles */
    .popup {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(0, 0, 0, 0.837);
        color: white;
        padding: 20px;
        border-radius: 10px;
        width: 300px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
        text-align: center;
        animation: popup-animation 0.5s ease forwards;
    }
    
    @keyframes popup-animation {
        from {
        transform: translate(-50%, -50%) scale(0.5);
        opacity: 0;
        }
        to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
        }
    }
    
    /* Button styles */
    .button {
        background-color: tomato;
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        font-size: 1em;
        margin-right: 10px;
        transition: transform 0.3s ease;
    }
    
    .button:hover {
        transform: translateY(-3px);
    }
    .blue {
        background-color: royalblue;
        margin-right: 0px;
        text-align: center;
    }

    .turn {
        margin: 0px -20px;
        margin-top: 20px;
        padding: 15px;
        background-color: black;
        text-align: center;
        color: white;
        font-weight: 700;
        font-size: 14px;
    }
    
    /* Animation for X and O emoji */
    @keyframes spin {
        0% {
        transform: rotate(0deg);
        }
        100% {
        transform: rotate(360deg);
        }
    }
    
    .cell-content {
        animation: spin 0.5s linear infinite;
    }
    
    /* Responsive styles */
    @media (max-width: 600px) {
        .board {
        grid-template-columns: repeat(4, 60px);
        grid-template-rows: repeat(4, 60px);
        }
    }
</style>
  