<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" ><path d="M725.54-171.62 536.62-360.54l63.22-63.23 188.93 188.92q12.77 12.77 12.77 31.62 0 18.84-12.77 31.61t-31.62 12.77q-18.84 0-31.61-12.77Zm-553.92 0q-12.77-12.77-12.77-31.61 0-18.85 12.77-31.62l245.54-245.53-93.39-93.01q-9.85 9.85-24.92 9.85-15.08 0-24.93-9.85l-19.53-19.92v83.46q0 12.46-11.04 16.89-11.04 4.42-19.89-4.43L122.54-598.31q-8.85-8.85-4.42-19.88 4.42-11.04 16.88-11.04h83.46l-18.54-18.54q-10.84-10.85-10.84-25.5t10.84-25.5l88.62-88.61q16.92-16.93 36.65-24.77 19.73-7.85 41.04-7.85 16.92 0 31.92 4.85 15 4.84 29.31 15.31 6.84 4.61 7.54 12.65.69 8.04-5.54 14.27L365-708.46l22 22q9.84 9.84 9.84 24.92 0 15.08-9.84 24.92l93.38 93.01 96.16-96.16q-6.69-12.15-9.77-25.11-3.08-12.97-3.08-27.27 0-54 37.23-91.23 37.23-37.23 91.23-37.23 6.85 0 12.5.3 5.66.31 11.89 1.93 7.46 2.61 9.77 10.57 2.3 7.97-3.54 13.81l-61.16 61.15Q656-727.23 656-720q0 7.23 5.61 12.85l45.54 45.54Q712.77-656 720-656q7.23 0 12.85-5.61L794-722.77q5.84-5.84 13.81-3.84 7.96 2 10.57 10.07 1.62 6.23 1.93 11.89.3 5.65.3 12.5 0 54-37.23 91.23-37.23 37.23-91.23 37.23-14.3 0-27.27-2.77-12.96-2.77-25.11-10.08L234.85-171.62q-12.77 12.77-31.62 12.77-18.84 0-31.61-12.77Z"/></svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>