<template>
    <div class="plus">
        <div class="image">
            <img src="../assets/logo.jpg"/>
        </div>
    </div>
</template>

<script setup>

import { ref, defineProps, defineEmits } from "vue";

// import { store } from "@/store/store";

const props = defineProps(['data'])
const emit = defineEmits(['close'])

const x = ref(props.data.x + 'px')
const y = ref(props.data.y + 'px')

setTimeout(() => {
    emit('close')
}, 1000);

</script>


<style scoped>
.plus {
    position: fixed;
    z-index: 20;

    color: white;
    font-weight: 800;
    font-size: 18px;

    animation: move_up 1s ease-out;

    display: flex;
    gap: 10px;
    fill: #FFD700;
}
@keyframes move_up {
    from {
        top: v-bind(y);
        left: v-bind(x);
        opacity: 1;
    }
    to {
        top: 20px;
        left: 50px;
        opacity: 0;
    }
}


.image {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;
}
img {
    width: 40px;
}
</style>