<template>
    <div class="popup">
        <div class="overlay"></div>
        <div class="box">
            <div class="title">
                Yunqi Cardz NFT
                <div class="close" @click="close()">
                    <close_icon :size="'30px'"/>
                </div>
            </div>
            <div class="content">
                <div class="image">
                    <img src="../../assets/yunqi nft.png"/>
                </div>
                <div class="text">
                    Welcome to Yunqicardz connect wallet holding the Yunqi Genesis Card and proceed to kickstart mining.
                </div>
                <div class="text">
                    Don't have any Genesis NFT? visit
                    <a href="https://opensea.io/collection/yunqi-genesis-cards/overview">
                        Yunqi Genesis Cards
                    </a> to get one
                </div>
                <div v-if="store.nfts > 0" class="btn">Already Claimed, Start Mining</div>
                <div v-else>
                    <div class="loading" v-if="loading">
                        <loading_vue/>
                    </div>
                    <div v-else>
                        <div v-if="isConnected">
                            <div class="btn" @click="connect()">Disconnect Wallet</div>
                            <div class="note">
                                Yunqi Cardz NFT not found in your wallet
                            </div>
                        </div>
                        <div class="btn" v-else @click="connect()">Connect Wallet</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import close_icon from '@/icons/close.vue';

import loading_vue from '../loading.vue';

import { defineEmits, watch, ref, inject } from 'vue';

import { store } from '@/store/store';

const toast = inject('toast')
const axios = inject('axios')


const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}

const loading = ref(false)
const details = ref({})
const login = ref(false)
// const 

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/vue'



// 1. Get projectId at https://cloud.walletconnect.com
const projectId = '725c82b8dc089481652ecce694e5f2c5'

// 2. Set chains
const mainnet = {
    chainId: 8453,
    name: 'Base Mainnet',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://mainnet.base.org'
}
// const mainnet = {
//     chainId: 84532,
//     name: 'Base Sepolia Testnet',
//     currency: 'ETH',
//     explorerUrl: 'https://base-sepolia.blockscout.com',
//     rpcUrl: 'https://base-sepolia.blockpi.network/v1/rpc/public'
// }


// 3. Create your application's metadata object
const metadata = {
    name: 'Yunqi Cardz',
    description: 'Yunqi Cardz',
    url: 'https://yunqicardz.com/', // url must match your domain & subdomain
    icons: ['https://yunqicardz.com/images/logo.png']
}


// 4. Create Ethers config
const ethersConfig = defaultConfig({
    /*Required*/
    metadata,

    /*Optional*/
    enableEIP6963: true, // true by default
    enableInjected: true, // true by default
    enableCoinbase: true, // true by default
    rpcUrl: '...', // used for the Coinbase SDK
    defaultChainId: 8453, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
const modal = createWeb3Modal({
    ethersConfig,
    chains: [mainnet],
    projectId,
    enableAnalytics: true, // Optional - defaults to your Cloud configuration
    enableOnramp: true // Optional - false as default
})

const connect = () => {
    modal.open()
}
import { useWeb3ModalAccount } from '@web3modal/ethers/vue'

const { address, chainId, isConnected } = useWeb3ModalAccount()

console.log('address', address.value);
console.log('chainId', chainId.value);
console.log('isConnected', isConnected.value);

import { BrowserProvider, Contract, formatEther  } from 'ethers'
import { useWeb3ModalProvider } from '@web3modal/ethers/vue'
const { walletProvider } = useWeb3ModalProvider()




watch(isConnected, async () => {
    console.log('isConnected', isConnected.value);
    if (isConnected.value) {
        if ( store.nfts > 0 ) { return }
        loading.value = true
        get_balance()
    }
})

async function get_balance() {

    loading.value = ref(true)
    login.value = ref(true)


    console.log('address', address.value);
    console.log('chainId', chainId.value);
    console.log('isConnected', isConnected.value);

    const provider = new BrowserProvider(walletProvider.value)
    const signer = await provider.getSigner()

    const abi = [
        // ERC-721 standard functions
        'function balanceOf(address owner) view returns (uint256)',
        'function ownerOf(uint256 tokenId) view returns (address)'
    ];
    
    const contract = new Contract("0x2a167bFCaeB8b44f670426Df52FbEbCb083c151E", abi, signer)
    const nftBalance = await contract.balanceOf(address.value)

    
    console.log('nft_balance', nftBalance);
    console.log('nft_real_balance', formatEther(nftBalance));
    console.log('nft_real_balance', Math.round(formatEther(nftBalance) * 1e18));

    details.value = {
        'address': address.value,
        'nft': Math.round(formatEther(nftBalance) * 1e18)
    }


    if (details.value.nft) {
        toast.show("Updating your NFT balance")

        axios.post('/user/update_nft', {
            'user_id': store.user_id,
            'nfts': details.value.nft,
            'wallet': details.value.address
        }).then(data => {
            let r = data.data
            if (r.status == 'ok') {
                store.nfts = details.value.nft
                loading.value = false
            }
        })
    } else {
        toast.show("Yunqi Cardz NFT not found in your wallet")
        
        loading.value = false
    }

    // console.log(details.value);
    

}



</script>

<style scoped>
.popup {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.2);
}
.box {
    position: relative;

    width: 100%;
    margin: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    
    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 10px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 600;
    text-align: center;
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}
.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.content {
    background-color: #321a02;
    outline: 3px solid #fff;
    padding: 20px;
    border-radius: 10px;

    max-height: 70vh;
    overflow: auto;
}

.image img {
    width: 100%;
}

.text {
    margin-bottom: 20px;
}
.text a {
    /* color: #321a02; */
    font-weight: 700;
    text-decoration: underline 2px white;
}
.btn {
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 12px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
}
.note {
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
}

.loading {
    width: fit-content;
    margin: 0 auto;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
}
</style>