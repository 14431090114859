<template>
    <div class="popup">
        <div class="overlay"></div>
        <div class="box">
            <div class="title">
                Convert Points
                <div class="close" @click="close()">
                    <close_icon :size="'30px'"/>
                </div>
            </div>
            <div class="content">
                <div class="flex">
                    <div class="title_flex">Points</div>
                    <div class="bal">Balance {{ store.points }}</div>
                </div>
                <div class="input">
                    <div class="image">
                        <img src="../../assets/coin2.png"/>
                    </div>
                    <div class="no">
                        {{ store.points }}
                    </div>
                </div>

                <div class="convert">
                    <convert_icon :size="'20px'"/>
                </div>
                
                <div class="flex">
                    <div class="title_flex">yunqi</div>
                    <div class="bal">Balance {{ store.balance }}</div>
                </div>
                <div class="input">
                    <div class="image">
                        <img src="../../assets/logo.jpg"/>
                    </div>
                    <div class="no">
                        {{ store.balance }}
                    </div>
                </div>

                
                <div class="title bottom">Coming Soon</div>
            </div>
        </div>
    </div>

</template>

<script setup>
import close_icon from '@/icons/close.vue';
import convert_icon from '@/icons/convert.vue';

import { defineEmits } from 'vue';

import { store } from '@/store/store';

const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}

</script>

<style scoped>

.popup {
    position: fixed;
    z-index: 2;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.2);
}
.box {
    position: relative;

    width: 100%;
    margin: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    
    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 10px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 600;
    text-align: center;
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}
.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.content {
    background-color: #321a02;
    outline: 3px solid #fff;
    padding: 20px;
    border-radius: 10px;
}
.convert {
    margin: 20px 0px;
    text-align: center;
    fill: white;
}
.flex {
    display: flex;
    justify-content: space-between;

}
.title_flex {
    font-size: 18px;
    font-weight: 600;
}
.bal {
    font-size: 18px;
    font-weight: 800;
}

.input {
    padding: 10px;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    overflow: hidden;
}
.no {
    font-size: 25px;
}

.image {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    overflow: hidden;
}
.image img {
    width: 50px;
}

.bottom {
    margin-top: 20px;
}
</style>