<template>
    <router-view/>
    <div class="overlay"></div>
</template>

<script setup>
document.title = 'Yungi cardz Game'

    
import { useWebAppTheme } from 'vue-tg'
const theme = useWebAppTheme()
theme.setHeaderColor('#0C0C0C')

import { useWebAppViewport } from 'vue-tg'
const appViewport = useWebAppViewport()
appViewport.expand()


import { inject, onUnmounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { store } from './store/store'
    
const route = useRoute()
const router = useRouter()


window.open = (function (open) {
	return function (url, _, features) {
		return open.call(window, url, "_blank", features);
	};
})(window.open);




const show_app = ref(false)
const init = () => {

    setTimeout(() => {
        
        console.log(route.query);
        
        if (!route.query.userid) {
            
            let user_id = localStorage.getItem('user_id')

            router.push({
                path: '/',
                query: {
                    'userid': user_id ? user_id : ''
                }
            })

        }
        show_app.value = true
        
    }, 1000);
}
init()



let increase_guage = null
let update_interval = null

onUnmounted(() => {
    clearInterval(increase_guage)
    clearInterval(update_interval)
})


const axios = inject('axios')

watch(() => store.loaded, () => {
    
    if (store.loaded) {
        if (increase_guage != null) {
            clearInterval(increase_guage)
        }
        if (update_interval != null) {
            clearInterval(update_interval)
        }
        increase_guage = setInterval(() => {
            
            if (store.energy_balance < store.energy_limit) {
                store.energy_balance += store.recharge_speed

                store.updates++
            } else {
                store.energy_balance = store.energy_limit
            }
        }, 1000);

        update_interval = setInterval(() => {
            if (store.updates > store.last_sent_update) {
                let current_update = store.updates

                axios.post('/user/update', {
                    'user_id': store.user_id,
                    'total_points': store.total_points,
                    'points': store.points,
                    'energy_balance': store.energy_balance
                }).then(() => {
                    store.last_sent_update = current_update
                })
            }
        }, 5000);
    }
})


</script>

<style>

@import 'https://fonts.googleapis.com/css2?family=Dosis:wght@200..800&display=swap';

#app {
    
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    font-family: "Dosis", sans-serif;
    
    background-image: url('./assets/doodle.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

body {
    padding: 0px;
    margin: 0px;
}
a {
    color: inherit;
    text-decoration: none;
}
.overlay {
    width: 100vw;
    height: 100vh;

    /* background-color: rgba(102, 41, 41, 0.5); */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), #1C1528);

    position: absolute;
    top: 0px;
    left: 0px;

    
    /* background-image: url('./assets/yunqi-bg-1.jpg');
    background-image: url('./assets/bg.svg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .2; */
}
.v--default-css .c-toast {
    padding: 10px 20px !important;
}

.v--default-css .c-toast--default {

    background-image: url('./assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    font-weight: 600;
}
</style>
