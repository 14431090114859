<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" ><path d="M189-170q-56.54 0-96.15-39.92Q53.23-249.85 52-307.16q0-8.46.81-16.69t2.81-16.84l84-336q13.13-50.57 53.46-81.94Q233.41-790 285-790h390q51.59 0 91.92 31.37t53.46 81.94l84 336q2 8.61 3.31 17.34t1.31 17.19q0 57.31-40.42 96.74Q828.15-170 771.03-170q-39.34 0-72.64-20.65-33.31-20.66-50.54-55.97L619.46-305q-6.15-12.5-18.46-18.75T575-330H385q-13.59 0-25.95 6.15-12.36 6.16-18.51 18.85l-28.39 58.38q-16.84 35.7-50.41 56.16Q228.17-170 189-170Zm351-354.62q14.69 0 25.04-10.34 10.34-10.35 10.34-25.04t-10.34-25.04q-10.35-10.34-25.04-10.34t-25.04 10.34q-10.34 10.35-10.34 25.04t10.34 25.04q10.35 10.34 25.04 10.34Zm80-80q14.69 0 25.04-10.34 10.34-10.35 10.34-25.04t-10.34-25.04q-10.35-10.34-25.04-10.34t-25.04 10.34q-10.34 10.35-10.34 25.04t10.34 25.04q10.35 10.34 25.04 10.34Zm0 160q14.69 0 25.04-10.34 10.34-10.35 10.34-25.04t-10.34-25.04q-10.35-10.34-25.04-10.34t-25.04 10.34q-10.34 10.35-10.34 25.04t10.34 25.04q10.35 10.34 25.04 10.34Zm80-80q14.69 0 25.04-10.34 10.34-10.35 10.34-25.04t-10.34-25.04q-10.35-10.34-25.04-10.34t-25.04 10.34q-10.34 10.35-10.34 25.04t10.34 25.04q10.35 10.34 25.04 10.34Zm-360.02 58.47q10.33 0 17.1-6.76 6.77-6.76 6.77-17.09v-46.15H410q10.33 0 17.09-6.76 6.76-6.75 6.76-17.07 0-10.33-6.76-17.1-6.76-6.77-17.09-6.77h-46.15V-630q0-10.33-6.76-17.09-6.75-6.76-17.07-6.76-10.33 0-17.1 6.76-6.77 6.76-6.77 17.09v46.15H270q-10.33 0-17.09 6.76-6.76 6.75-6.76 17.07 0 10.33 6.76 17.1 6.76 6.77 17.09 6.77h46.15V-490q0 10.33 6.76 17.09 6.75 6.76 17.07 6.76Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>