<template>
    <div>
        
        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" ><path d="M245.02-478q0 43.57 16.64 84.99 16.64 41.42 51.69 76.94l8.08 8.09v-50.35q0-17.71 12.1-29.69T363.35-400q17.72 0 29.69 11.98 11.98 11.98 11.98 29.69v157.61q0 19.15-13.17 32.33-13.18 13.17-32.33 13.17H202.15q-17.72 0-29.69-12.1-11.98-12.09-11.98-29.81t12.1-29.7q12.09-11.97 29.81-11.97h60.2l-12.89-11.61q-52.24-47.44-73.96-107.16-21.72-59.71-21.72-120.43 0-96.39 50.03-174.92 50.04-78.54 133.91-119.78 15.67-8.47 32.25.08 16.57 8.55 22.05 26.71 5.24 17.15-1.34 33.94-6.57 16.8-22.49 25.75-56.32 31.29-89.86 86.59-33.55 55.3-33.55 121.63Zm469.96-4q0-43.57-16.64-84.99-16.64-41.42-51.69-76.94l-8.08-8.09v50.35q0 17.71-11.98 29.69T596.89-560q-17.72 0-29.81-12.1-12.1-12.1-12.1-29.81v-157.37q0-19.15 13.17-32.33 13.18-13.17 32.33-13.17h157.37q17.72 0 29.69 11.98 11.98 11.97 11.98 29.69t-11.98 29.82q-11.97 12.09-29.69 12.09h-60.44l12.89 11.61q49.72 49.96 72.7 108.42 22.98 58.45 22.98 119.17 0 96.39-50.03 174.92-50.04 78.54-133.91 119.78-15.67 8.47-32.25-.08-16.57-8.55-22.05-26.71-5.24-17.15 1.34-33.94 6.57-16.8 22.49-25.75 56.32-31.29 89.86-86.59 33.55-55.3 33.55-121.63Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>