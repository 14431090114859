<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960"><path d="M424.15-346.15q21.54 21.53 55.12 20.03t49.04-24.03l161.33-233.69q8.59-12.93-1.95-23.47-10.54-10.53-23.49-1.96L430.15-448.31q-22.92 15.7-25.42 47.9t19.42 54.26ZM204-180q-16.55 0-30.47-7.58-13.91-7.58-22.61-21.96-24.84-43.92-37.88-91.92Q100-349.46 100-400q0-78.85 29.93-148.2 29.92-69.35 81.22-120.65 51.3-51.3 120.65-81.22Q401.15-780 480-780q78.13 0 146.73 29.45t120.05 80.27q51.45 50.82 81.72 118.86 30.27 68.04 30.88 146.12.62 52.38-12.11 101.99-12.73 49.62-38.81 95.31-8.31 14.38-22.34 21.19-14.04 6.81-30.74 6.81H204Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>