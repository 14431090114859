<template>
    <div class="home">

        <details_vue/>

        <div class="bottom-section">
            <div class="nav-section ">
                <div class="nav" @click="show_profile = true">
                    <div>
                        <profile_icon :size="'25px'"/>
                        Profile
                    </div>
                </div>
                <div class="nav" @click="show_tasks = true">
                    <div>
                        <task_icon :size="'25px'"/>
                        Tasks
                    </div>
                </div>
                <div class="nav" @click="show_referral = true">
                    <div>
                        <referral_icon :size="'25px'"/>
                        Referrals
                    </div>
                </div>
            </div>
            <div>
                <div class="pop" v-if="show_earn_options">
                    <router-link to="/taptap">
                        <div class="pop_option">
                            <div>
                                <tap_icon :size="'20px'"/>
                                Tap Tap
                            </div>
                        </div>
                    </router-link>
                    <div class="pop_option" @click="soon()">
                        <div>
                            <game_icon :size="'20px'"/>
                            Game
                        </div>
                    </div>
                </div>
                <div class="earn" @click="show_earn_options = !show_earn_options">
                    Earn
                </div>
            </div>
            <div class="nav-section">
                <div class="nav" @click="show_nft = true">
                    <div>
                        <nft_icon :size="'25px'"/>
                        NFT
                    </div>
                </div>
                <div class="nav" @click="soon()">
                    <div>
                        <lobby_icon :size="'25px'"/>
                        Lobby
                    </div>
                </div>
                <div class="nav" @click="soon()">
                    <div>
                        <spin_icon :size="'25px'"/>
                        Spin
                    </div>
                </div>
                <div class="nav" @click="show_mining = true">
                    <div>
                        <mine_icon :size="'25px'"/>
                        Mining
                    </div>
                </div>
            </div>
        </div>

        <profile_vue v-if="show_profile" @close="show_profile = false"/>

        <nft_vue v-if="show_nft" @close="show_nft = false"/>
        <mining_uve v-if="show_mining" @close="show_mining = false"/>
        <tasks_vue v-if="show_tasks" @close="show_tasks = false"/>
        <referral_vue v-if="show_referral" @close="show_referral = false"/>
    </div>
</template>

<script setup>

import details_vue from '@/components/details.vue'

import profile_icon from '@/icons/profile.vue'
import task_icon from '@/icons/task.vue'
import referral_icon from '@/icons/referral.vue'

import nft_icon from '@/icons/nft.vue'
import lobby_icon from '@/icons/lobby.vue'
import mine_icon from '@/icons/mine.vue'
import spin_icon from '@/icons/spin.vue'

import tap_icon from '@/icons/tap.vue'
import game_icon from '@/icons/game.vue'

import profile_vue from '@/components/popup/profile.vue'

import nft_vue from '@/components/popup/nft.vue'
import mining_uve from '@/components/popup/mining.vue'
import tasks_vue from '@/components/popup/tasks.vue'
import referral_vue from '@/components/popup/referral.vue'

// import { store } from '@/store/store'

import { ref, inject } from 'vue'

const toast = inject('toast')

const show_earn_options = ref(false)

const show_profile = ref(false)

const show_nft = ref(false)
const show_mining = ref(false)
const show_tasks = ref(false)
const show_referral = ref(false)

const soon = () => {
    toast.show('Coming soon')
}

</script>

<style scoped>
.home {
    
    height: 100vh;
    width: 100vw;

    color: white;

    position: relative;
    z-index: 1;
}

.wallet-section {
    position: absolute;
    top: 20px;
    right: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.connect {
    padding: 8px 20px;
    font-weight: 500;
    font-size: 18px;
    background-color: #d37f57;
    border: 2px solid #2a1627;
    outline: 3px solid #fff;
    border-radius: 5px;
}

.bottom-section {
    width: 100vw;
    box-sizing: border-box;
    position: absolute;
    bottom: 50px;
    right: 0px;
    padding: 0px 20px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}
.nav-section {

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.nav {
    width: 50px;
    height: 50px;
    font-weight: 700;

    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;
    text-align: center;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    
    background-image: url('../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.pop {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    animation: enter ease-in .2s;
}
@keyframes enter {
    from {
        margin-bottom: -30px;
        opacity: 0;
    }
    to {
        margin-bottom: 0px;
        opacity: 1;
    }
}
.earn {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    margin-top: 20px;
    border-radius: 50%;
    
    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    font-weight: 600;

    display: flex;
    align-items: center;
    justify-content: center;
    
    background-image: url('../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.pop_option {
    
    width: 50px;
    height: 50px;
    font-weight: 700;

    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;
    text-align: center;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    
    background-image: url('../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
</style>