<template>
    <div id="app">
        <h1>Tic Tac Toe</h1>
        <div class="board">
            <div v-for="(cell, index) in board" :key="index" class="cell" @click="selectCell(index)"
                :style="{ cursor: cell ? 'default' : 'pointer' }">
                {{ cell === "X" ? "❌" : cell === "O" ? "⭕" : "" }}
            </div>
        </div>
        <div v-if="winner !== null" class="popup">
            <p v-if="winner === 'X' || winner === 'O'">
                Congratulations! {{ winner }} wins!
            </p>
            <p v-else>It's a draw!</p>
            <button @click="resetGame">Play Again</button>
        </div>
        <div v-else>
            <p>{{ currentPlayer }}'s turn</p>
        </div>
        <div>
            <button @click="undo" :disabled="!canUndo">Undo</button>
            <button @click="redo" :disabled="!canRedo">Redo</button>
        </div>
        <div>
            <label>Difficulty Level:</label>
            <select v-model="difficulty">
                <option value="easy">Easy</option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
            </select>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";

const currentPlayer = ref("X");
const board = ref(Array(9).fill(null));
const winner = ref(null);
const difficulty = ref("medium");
const moves = ref([]);

const canUndo = computed(() => moves.value.length > 0);
const canRedo = computed(() => moves.value.length < board.value.length);

function selectCell(index) {
    if (!board.value[index] && !winner.value) {
        board.value[index] = currentPlayer.value;
        moves.value.push({ index, player: currentPlayer.value });
        if (checkWin()) {
            winner.value = currentPlayer.value;
        } else {
            currentPlayer.value = currentPlayer.value === "X" ? "O" : "X";
            if (currentPlayer.value === "O") {
                setTimeout(makeComputerMove, 500);
            }
        }
    }
}

function checkWin() {
    const winConditions = [
        [0, 1, 2],
        [3, 4, 5],
        [6, 7, 8],
        [0, 3, 6],
        [1, 4, 7],
        [2, 5, 8],
        [0, 4, 8],
        [2, 4, 6],
    ];
    return winConditions.some((condition) => {
        const [a, b, c] = condition;
        return (
            board.value[a] &&
            board.value[a] === board.value[b] &&
            board.value[a] === board.value[c]
        );
    });
}

function resetGame() {
    currentPlayer.value = "X";
    board.value = Array(9).fill(null);
    winner.value = null;
    moves.value = [];
}

function makeComputerMove() {
    const emptyCells = board.value.reduce((acc, cell, index) => {
        if (!cell) acc.push(index);
        return acc;
    }, []);
    let index;
    if (difficulty.value === "easy") {
        index = emptyCells[Math.floor(Math.random() * emptyCells.length)];
    } else {
        // Implement more advanced AI for medium and hard difficulties
        index = emptyCells[Math.floor(Math.random() * emptyCells.length)];
    }
    selectCell(index);
}

function undo() {
    if (canUndo.value) {
        const lastMove = moves.value.pop();
        board.value[lastMove.index] = null;
        winner.value = null;
        currentPlayer.value = lastMove.player;
    }
}

function redo() {
    if (canRedo.value) {
        const nextMove = board.value.findIndex(
            (cell, index) => index >= moves.value.length && !cell
        );
        if (nextMove !== -1) {
            selectCell(nextMove);
        }
    }
}

onMounted(resetGame);
</script>

<style scoped>
/* Container styles */
#app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(to bottom right, #4c2e49, #6e4d73);
    color: #fff;
    font-family: Arial, sans-serif;

    position: relative;
    z-index: 1;
}

/* Title styles */
h1 {
    font-size: 48px;
    text-align: center;
    margin-bottom: 30px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Board styles */
.board {
    display: grid;
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: repeat(3, 100px);
    gap: 10px;
    border: 5px solid #fff;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    animation: fadeIn 1s;
}

/* Cell styles */
.cell {
    border: 2px solid #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    background-color: rgba(255, 255, 255, 0.3);
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.cell:hover {
    background-color: rgba(255, 255, 255, 0.5);
}

/* Winning cell styles */
.cell.winning {
    background-color: rgba(255, 255, 0, 0.7);
}

/* Disabled cell styles */
.cell:disabled {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: not-allowed;
}

/* Popup styles */
.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.3);
}

.popup p {
    font-size: 24px;
    margin-bottom: 20px;
}

.popup button {
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.popup button:hover {
    background-color: #0056b3;
}

/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: scale(0.8);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes backgroundChange {
    0% {
        background: linear-gradient(to bottom right, #4c2e49, #6e4d73);
    }

    50% {
        background: linear-gradient(to bottom right, #6e4d73, #4c2e49);
    }

    100% {
        background: linear-gradient(to bottom right, #4c2e49, #6e4d73);
    }
}
</style>
