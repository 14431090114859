<template>
    <div class="popup">
        <div class="overlay"></div>
        <div class="box">
            <div class="title">
                Referrals
                <div class="close" @click="close()">
                    <close_icon :size="'30px'"/>
                </div>
            </div>
            <div class="content">
                <div class="small-text">Invite Link</div>
                <div class="invite" @click="copy()">
                    <div>
                        <div class="link">
                            https://t.me/YunqicardzBot?start={{ store.telegram_id }}
                        </div>
                    </div>
                    <div class="icon">
                        <copy_icon :size="'20px'"/>
                    </div>
                </div>

                <div class="text">Referrals ({{ store.referrals }})</div>
            </div>
        </div>
    </div>
</template>

<script setup>
import close_icon from '@/icons/close.vue';
import copy_icon from '@/icons/copy.vue';

import { defineEmits, inject } from 'vue';

const emit = defineEmits(['close'])

const toast = inject('toast')

import { store } from '@/store/store';

const close = () => {
    emit('close')
}

const copy = () => {
    
    let text = `https://t.me/YunqicardzBot?start=${store.telegram_id}`
    
    navigator.clipboard.writeText(text).then(function() {
        toast.show('Copied')
    });
}

</script>

<style scoped>

.popup {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.2);
}
.box {
    position: relative;

    width: 100%;
    margin: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    
    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 10px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 600;
    text-align: center;
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}
.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.content {
    background-color: #321a02;
    outline: 3px solid #fff;
    padding: 20px 20px;
    border-radius: 10px;

    min-height: 50vh;
    max-height: 70vh;

    overflow: auto;
}
.invite {
    
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px 20px;
    margin: 0px -20px;
    fill: white;
}
.small-text {
    
    /* font-size: 14px; */
    margin-bottom: 3px;
    font-weight: 600;
}
.link {
    font-weight: 600;
    user-select: all;
}
.text {
    font-size: 18px;
    font-weight: 600;
    margin-top: 20px;
}
</style>