<template>
    <div class="popup">
        <div class="overlay"></div>
        <div class="box">
            <div class="title">
                Mining
                <div class="close" @click="close()">
                    <close_icon :size="'30px'"/>
                </div>
            </div>
            <div class="content">
                <div class="image">
                    <img src="../../assets/mining.jpg"/>
                </div>
                <div class="details">
                    <div class="flex">
                        <div class="label">NFTs:</div>
                        <div class="no">{{store.nfts}}</div>
                    </div>
                    <div class="flex">
                        <div class="label">Earning:</div>
                        <div class="no"> {{store.nfts * COIN_PER_MINUTE}} / Minutes</div>
                    </div>
                </div>
                <div class="btn" v-if="store.nfts == 0" @click="link_nfts()">Link NFTs to start mining</div>
                <div v-else>
                    <div v-if="!store.mining">
                        <div class="loading" v-if="loading">
                            <loading_vue/>
                        </div>
                        <div class="btn" v-else @click="start_mining()">Start Mining</div>
                    </div>
                    <div v-else>
                        <div class="mined">
                            {{to_claim}}
                        </div>
                        
                        <div v-if="claim_now">
                            <div class="loading" v-if="claim_loading">
                                <loading_vue/>
                            </div>
                            <div class="btn" v-else @click="claim()">Claim Now</div>
                        </div>

                        <div class="claim_in btn" v-else>
                            Claim in <div class="countdown">
                                {{countdown.hour}} Hr : {{countdown.minute}} Min : {{countdown.sec}} Sec
                            </div> 
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>


import moment from 'moment'

import close_icon from '@/icons/close.vue';

import loading_vue from '../loading.vue';

import { defineEmits, inject, onMounted, ref } from 'vue';

const COIN_PER_MINUTE = ref(0.05)

import { store } from '@/store/store';
const toast = inject('toast')
const axios = inject('axios')

const emit = defineEmits(['close'])

const loading = ref(false)

const close = () => {
    emit('close')
}

const link_nfts = () => {
    
    toast.show("Connect your wallet and link your NFT's to start mining")
}


onMounted(() => {
    if (store.mining) {
        axios.post('/user/mining/details')
        .then(data => {
            let r = data.data
            if (r.status == 'ok') {
                current_time.value = r.current_time
                mining_started.value = store.mining_started
                mining_ends.value = new Date(mining_started.value + 3 * (60 * 60 * 1000))

                
                calculation_loop()
            }
        })
    }
})


const mining_started = ref(0)
const mining_ends = ref(0)
const current_time = ref(0)

const total_coin = ref(0)
const to_claim = ref(0)
const countdown = ref({
    'hour': 0,
    'minute': 0,
    'sec': 0
})
const claim_now = ref(false)
const claim_loading = ref(false)

const start_mining = () => {
    axios.post('/user/mining/start', {
        'user_id': store.user_id
    }).then(data => {
        let r = data.data
        if (r.status == 'ok') {
            mining_started.value = r.mining_started
            mining_ends.value = new Date(r.current_time + 3 * (60 * 60 * 1000))
            current_time.value = r.mining_started

            store.mining = true
            store.mining_started = r.mining_started

            calculation_loop()
        }
    })
}

const interval = ref(null)

const calculation_loop = () => {
    
                
    let total_time = mining_ends.value - mining_started.value
    total_coin.value = ((total_time / 1000) / 60) * (store.nfts * COIN_PER_MINUTE.value)

    console.log(total_coin.value);
    
    
    loading.value = false

    if (store.mining) {
        interval.value = setInterval(() => {

            let duration = mining_ends.value - current_time.value

            if (duration > 0) {

                let passed_time = current_time.value - mining_started.value
                to_claim.value = (((passed_time / 1000) / 60) * (store.nfts * COIN_PER_MINUTE.value)).toFixed(2)


                let moment_duration =  moment.duration(duration)

                countdown.value = {
                    'hour': moment_duration.hours(),
                    'minute': moment_duration.minutes(),
                    'sec': moment_duration.seconds()
                }

                current_time.value += 1000
            } else {
                claim_now.value = true
                to_claim.value = total_coin.value
            }
            
        }, 1000);
    }
}

const claim = () => {
    claim_loading.value = true

    axios.post('/user/mining/claim', {
        user_id: store.user_id
    }).then(data => {
        let r = data.data
        if (r.status == 'ok') {
            store.balance = r.balance
            store.mining = false
            
            toast.show("Reward Claimed")

            claim_loading.value = false
        }
    })
}

</script>

<style scoped>

.popup {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.2);
}
.box {
    position: relative;

    width: 100%;
    margin: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    
    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 10px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 600;
    text-align: center;
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}
.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.content {
    background-color: #321a02;
    outline: 3px solid #fff;
    padding: 20px;
    border-radius: 10px;
}

.image {
    border-radius: 10px;
    overflow: hidden;
}
.image img {
    width: 100%;
    display: block;
}

.details {
    margin: 20px 0px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}
.flex {
    display: flex;
    gap: 10px;
}
.no {
    font-weight: 600;
}

.btn {
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 12px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
}

.loading {
    width: fit-content;
    margin: 0 auto;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.mined {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}
.claim_in {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background-image: none;
}
</style>