<template>
    
    <div class="popup">
        <div class="overlay"></div>
        <div class="box">
            <div class="title">
                Boost
                <div class="close" @click="close()">
                    <close_icon :size="'30px'"/>
                </div>
            </div>
            <div class="content">
                <div class="boost">
                    <div class="boost-flex" @click="show_recharge = !show_recharge">
                        <div class="icon">
                            <speed_icon :size="'30px'"/>
                        </div>
                        <div>
                            <div class="name">Recharge Speed</div>
                            <div class="amount">
                                <div class="coin">
                                    <img src="@/assets/coin2.png"/>
                                </div>
                                <div class="no">
                                    {{store.recharge_speed_upgrades[store.recharge_speed].toLocaleString()}}
                                </div>
                            </div>
                        </div>
                        <div class="left">
                            <arrow_icon :size="'20px'" class="i" :class="[show_recharge ? 'rot' : '']"/>
                        </div>
                    </div>
                    <recharge_vue v-if="show_recharge" @close="show_recharge = false"/>
                </div>
                <div class="boost">
                    <div class="boost-flex" @click="show_energy = !show_energy">
                        <div class="icon">
                            <battery_icon :size="'30px'"/>
                        </div>
                        <div>
                            <div class="name">Energy Limit</div>
                            <div class="amount">
                                <div class="coin">
                                    <img src="@/assets/coin2.png"/>
                                </div>
                                <div class="no">
                                    {{store.energy_limit_upgrades[store.energy_limit_level]['coin'].toLocaleString()}}
                                </div>
                            </div>
                        </div>
                        <div class="left">
                            <arrow_icon :size="'20px'" class="i" :class="[show_energy ? 'rot' : '']"/>
                        </div>
                    </div>
                    <energy_vue v-if="show_energy" @close="show_energy = false"/>
                </div>
                <div class="boost">
                    <div class="boost-flex" @click="show_multitap = !show_multitap">
                        <div class="icon">
                            <tap_icon :size="'30px'"/>
                        </div>
                        <div>
                            <div class="name">Multitap</div>
                            <div class="amount">
                                <div class="coin">
                                    <img src="@/assets/coin2.png"/>
                                </div>
                                <div class="no">
                                    {{store.multitap_upgrades[store.multitap].toLocaleString()}}
                                </div>
                            </div>
                        </div>
                        <div class="left">
                            <arrow_icon :size="'20px'" class="i" :class="[show_multitap ? 'rot' : '']"/>
                        </div>
                    </div>
                    <multitap_vue v-if="show_multitap" @close="show_multitap = false"/>
                </div>
            </div>
        </div>


    </div>

</template>

<script setup>
import close_icon from '@/icons/close.vue';

import speed_icon from '@/icons/speed.vue';
import battery_icon from '@/icons/battery.vue';
import tap_icon from '@/icons/tap.vue';

import arrow_icon from '@/icons/arrow2.vue';

import recharge_vue from './boost/recharge.vue';
import energy_vue from './boost/energy.vue';
import multitap_vue from './boost/multitap.vue'

import { store } from '@/store/store';
import { ref, defineEmits } from 'vue';

const emit = defineEmits(['close'])

const close = () => {
    emit('close')
}


const show_recharge = ref(false)
const show_energy = ref(false)
const show_multitap = ref(false)

</script>

<style scoped>

.popup {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
}
.overlay {
    position: fixed;
    top: 0px;
    left: 0px;

    width: 100vw;
    height: 100vh;

    background-color: rgba(0, 0, 0, 0.2);
}
.box {
    position: relative;

    width: 100%;
    margin: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.title {
    
    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 10px;
    border-radius: 10px;

    font-size: 18px;
    font-weight: 600;
    text-align: center;
    
    background-image: url('../../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    position: relative;
}
.close {
    position: absolute;
    right: 10px;
    top: 5px;
}

.content {
    background-color: #321a02;
    outline: 3px solid #fff;
    padding: 20px 0px;
    border-radius: 10px;

    /* min-height: 50vh; */
    max-height: 70vh;

    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

.boost-flex {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    gap: 15px;
    background-color: rgba(0, 0, 0, 0.4);
    fill: white;
}

.name {
    font-weight: 700;
    margin-bottom: 3px;
}
.amount {
    display: flex;
    gap: 5px;
}
.coin img {
    width: 20px;
}
.no {
    font-size: 14px;
    line-height: 22px;
}

.left {
    margin-left: auto;
}
.i {

    transition: .2s;
}
.rot {
    transform: rotate(90deg);
}

</style>