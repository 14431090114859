<template>
    <div class="splash">
        <div>
            <div class="image">
                <img src="../assets/yunqi.png"/>
            </div>
            Yunqi Cards
            <div class="loading">
                <loading_vue/>
            </div>
        </div>
    </div>
</template>

<script setup>

import loading_vue from "@/components/loading.vue";

import { onMounted, inject } from "vue";
import { useRoute, useRouter } from "vue-router";

import { store } from '@/store/store'

const axios = inject('axios')

const router = useRouter()
const route = useRoute()


onMounted(() => {
    setTimeout(() => {
        
        if (route.query.userid) {
            
            store.user_id = route.query.userid
            
            localStorage.setItem('user_id', route.query.userid)
            
            axios.post('/user/onboard', {
                'user_id': route.query.userid
            }).then(data => {
                let r = data.data
                if (r.status == 'ok') {
                    
                    for (let key in r) {
                        if (key != 'status') {
                            store[key] = r[key]
                        }
                    }
                    
                    let current_time = r.current_time
                    
                    let last_time = r.updated_at
                    
                    const seconds_pass = parseInt((current_time - last_time) / 1000)

                    console.log(seconds_pass);
                    
                    
                    store.energy_balance += seconds_pass * store.recharge_speed
                    
                    if (store.energy_balance > store.energy_limit) {
                        store.energy_balance = store.energy_limit
                    }

                    
                    store.updates++
                    
                    
                    store.loaded = true

                    router.push('/home')

                }
            })
        }

        
    }, 1000);
})

</script>

<style scoped>
.splash {
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    /* background-color: black; */
    color: white;

    font-size: 25px;
    font-weight: 700;

    position: relative;
    z-index: 2;
}
.image {
    text-align: center;
}
.image img {
    width: 100px;
}

.loading {
    width: fit-content;
    margin: 20px auto;
}
</style>