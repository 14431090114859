<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" ><path d="M432.85-100q-30.31 0-57.31-12.77-27-12.77-45.15-36.69L139.77-396q-6.08-7.46-5.46-17.08.61-9.61 7.08-16.07 15-16 35.88-19.23 20.88-3.24 39.11 7.92l97.47 52.31v-316.46q0-12.75 8.63-21.38 8.62-8.62 21.38-8.62t21.68 8.62q8.92 8.63 8.92 21.38v195.38h297.08q45.19 0 76.82 31.63Q780-445.96 780-400.77v150.74q0 62.18-44.18 106.11Q691.65-100 629.61-100H432.85ZM474-599.62q-12.77 0-21.38-8.62-8.62-8.62-8.62-21.37 0-2.39 3.85-15 8-14 12-28.5t4-31.5q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 17 4 31.5t12 28.5q2.3 3.75 3.07 7.5t.77 7.51q0 12.75-8.29 21.37-8.29 8.61-21.11 8.61-8.29 0-15.35-4.46-7.07-4.46-10.78-11.15-11.85-20.08-18.08-42.61-6.23-22.54-6.23-46.88 0-74.82 52.54-127.35 52.53-52.54 127.46-52.54 74.92 0 127.46 52.54 52.53 52.53 52.53 127.35 0 24.34-6.23 46.88-6.23 22.53-18.07 42.61-3.76 6.69-10.52 11.15T474-599.62Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>