<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960" ><path d="M530-155v-60.15q0-12.85 7.42-22.89 7.43-10.04 20.27-12.65L686.92-280l-129.23-29.31q-12.84-2.61-20.27-12.65Q530-332 530-344.85V-405q0-20.69 17.08-31.23 17.07-10.54 35.15-1.31l250.38 125q20.08 9.85 20.08 32.54 0 22.69-20.08 32.54l-250.38 125q-18.08 9.23-35.15-1.31Q530-134.31 530-155Zm0 58.84v-147.69L686.92-280 530-316.15v-147.69L897.69-280 530-96.16ZM200-140q-24.92 0-42.46-17.54T140-200v-155.08q41.08-5 70.54-34.15Q240-418.39 240-460t-29.46-70.77q-29.46-29.15-70.54-34.15V-720q0-24.92 17.54-42.46T200-780h160q5.39-38.15 33.42-62.92 28.04-24.77 66.58-24.77t66.58 24.77Q554.61-818.15 560-780h160q24.92 0 42.46 17.54T780-720v291.54L558.69-539.31q-36.15-18.07-70.5 2.81-34.34 20.89-34.34 61.65v236.47q-39.23 2.99-66.12 28.26-26.88 25.27-32.65 70.12H200Z"/></svg>

    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>