import { createRouter, createWebHistory } from 'vue-router'
import splash from '../views/splash.vue'
import home from '../views/home.vue'
import taptap from '@/views/taptap.vue'


import game1 from '../views/game1.vue'
import game2 from '../views/game2.vue'
import game3 from '../views/game3.vue'
// import game4 from '../views/game4.vue'
import pong from '../views/pong.vue'
import MeteorDefender from '../views/MeteorDefender.vue'


const routes = [
    {
        path: '/',
        name: 'splash',
        component: splash
    },
    {
        path: '/home',
        name: 'home',
        component: home
    },
    {
        path: '/taptap',
        name: 'taptap',
        component: taptap
    },
    {
        path: '/game1',
        name: 'game1',
        component: game1
    },
    {
        path: '/game2',
        name: 'game2',
        component: game2
    },
    {
        path: '/game3',
        name: 'game3',
        component: game3
    },
    // {
    //     path: '/game4',
    //     name: 'game4',
    //     component: game4
    // },
    {
        path: '/pong',
        name: 'pong',
        component: pong
    },
    {
        path: '/meteor',
        name: 'meteor',
        component: MeteorDefender
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
