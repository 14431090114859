<template>
    <div id="game-board" @click="shoot" v-if="gameStarted">
      <!-- Meteors -->
      <div v-for="(meteor, index) in meteors" :key="index"
           :style="{ top: meteor.y + 'px', left: meteor.x + 'px' }"
           class="meteor"></div>
      <!-- Scoreboard and City SVG -->
      <div class="score-board">Score: {{ score }}</div>
      <svg class="cityscape" viewBox="0 0 1024 150" xmlns="http://www.w3.org/2000/svg">
        <!-- Simplified city buildings -->
        <rect x="10" y="50" width="50" height="100" style="fill: grey;"/>
        <rect x="100" y="30" width="70" height="120" style="fill: lightgrey;"/>
        <rect x="220" y="70" width="50" height="80" style="fill: grey;"/>
        <rect x="320" y="40" width="90" height="110" style="fill: lightgrey;"/>
        <rect x="460" y="20" width="80" height="130" style="fill: grey;"/>
        <rect x="600" y="60" width="60" height="90" style="fill: lightgrey;"/>
        <rect x="720" y="10" width="100" height="140" style="fill: grey;"/>
        <rect x="870" y="45" width="50" height="105" style="fill: lightgrey;"/>
      </svg>
      <button v-if="gameOver" @click="restartGame" class="restart-button">Restart Game</button>
    </div>
    <div v-else class="start-screen">
      <p>Welcome to Meteor Defender! Click anywhere to shoot down the meteors and protect the city. Click 'Start Game' to begin!</p>
      <button @click="startGame">Start Game</button>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, onUnmounted } from 'vue';
  
  export default {
    setup() {
      const meteors = ref([]);
      const score = ref(0);
      const gameOver = ref(false);
      const gameStarted = ref(false);
      let interval = null;
  
      function spawnMeteor() {
        meteors.value.push({
          x: Math.random() * window.innerWidth - 60,
          y: -60,
        });
      }
  
      function moveMeteors() {
        meteors.value.forEach(meteor => {
          meteor.y += 10;
          if (meteor.y > window.innerHeight - 150) { // Just above the cityscape
            gameOver.value = true;
            clearInterval(interval);
          }
        });
      }
  
      function shoot() {
        score.value += 10; // Scoring by clicking anywhere
      }
  
      function startGame() {
        gameStarted.value = true;
        restartGame();
      }
  
      function restartGame() {
        meteors.value = [];
        score.value = 0;
        gameOver.value = false;
        interval = setInterval(() => {
          moveMeteors();
          if (Math.random() < 0.1) {
            spawnMeteor();
          }
        }, 100); // Slower meteor spawning for visibility
      }
  
      onMounted(() => {
      });
  
      onUnmounted(() => {
        clearInterval(interval);
      });
  
      return { meteors, score, gameOver, gameStarted, shoot, restartGame, startGame };
    }
  }
  </script>
  
  <style>
  #game-board {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    overflow: hidden;
  }
  
  .meteor {
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: red; /* Temporary color, replace with path to a proper meteor image */
    border-radius: 50%;
    background-size: cover;
  }
  
  .score-board {
    position: fixed;
    top: 10px;
    left: 10px;
    color: white;
    font-size: 24px;
  }
  
  .cityscape {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 150px;
    fill: #ccc;
  }
  
  .restart-button, .start-screen button {
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: blue;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .start-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    text-align: center;
    padding: 20px;
  }
  </style>
  