<template>
    <div class="box2">
        <div class="title">Upgrade</div>
        <div class="flex">
            <div class="key">New level:</div>
            <div class="value">level {{ new_level }}</div>
        </div>
        <div class="flex">
            <div class="key">Amount:</div>
            <div class="value">
                <div class="coin">
                    <img src="@/assets/coin2.png"/>
                </div>
                <div class="no">
                    {{coin.toLocaleString()}}
                </div>
            </div>
        </div>
        <loading_vue class="loading" v-if="loading"/>
        <div class="btn" v-else @click="proceed()">Upgrade</div>
    </div>
</template>

<script setup>

import loading_vue from '@/components/loading.vue'

import { onMounted, ref, inject, defineEmits } from 'vue';

import { store } from '@/store/store';

const toast = inject('toast')
const axios = inject('axios')

const new_level = ref(0)
const coin = ref(0)
const loading = ref(false)


const emit = defineEmits(['close'])


onMounted(() => {
    new_level.value = store.recharge_speed + 1
    coin.value = store.recharge_speed_upgrades[store.recharge_speed]
})

const close = () => {
    emit('close')
}

const proceed = () => {
    if (store.points < coin.value) {
        toast.show('Not enough points')
        return
    }

    loading.value = true

    axios.post('/user/boost/recharge_speed', {
        'user_id': store.user_id
    })
    .then(data => {
        loading.value = false
        let r = data.data
        if (r.status == 'ok') {
            toast.show('Upgraded')
            store.points -= coin.value
            store.recharge_speed = new_level.value

            close()
        }
    })

}

</script>

<style scoped>
.box2 {
    background-color: rgba(0, 0, 0, 0.4);
    border-top: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 20px;
}
.title {
    font-weight: 500;
}
.flex {
    display: flex;
    align-items: center;
    gap: 10px;
}
.key {
    opacity: .6;
}
.value {
    font-weight: 500;

    display: flex;
    align-items: center;
    gap: 5px;
}
.coin img {
    width: 20px;
}
.no {
    line-height: 25px;
}

.btn {
    
    background-image: url('@/assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;

    padding: 12px;
    text-align: center;
    border-radius: 10px;
    font-weight: 600;
}

.loading {
    width: fit-content;
    margin: 0 auto;
    height: 48px;

    display: flex;
    align-items: center;
    justify-content: center;
}
</style>