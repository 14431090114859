<template>
    <div class="details">
        <div class="balance" @click="show_convert = true">
            <div class="image">
                <img src="../assets/coin2.png"/>
            </div>
            {{store.points}}
            <div class="icon">
                <convert_icon :size="'15px'"/>
            </div>
        </div>
        <div class="balance">
            <div class="image">
                <img src="../assets/logo.jpg"/>
            </div>
            {{store.balance}}
        </div>

        <convert_vue v-if="show_convert" @close="show_convert = false"/>
    </div>
</template>

<script setup>

import convert_icon from '@/icons/convert.vue';
import convert_vue from './popup/convert.vue';

import { store } from '@/store/store';
import { ref } from 'vue';


const show_convert = ref(false)


</script>

<style scoped>

.details {
    position: absolute;
    top: 20px;
    left: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.balance {
    width: 90px;
    padding: 5px 0px;
    font-weight: 500;
    font-size: 18px;
    background-color: #d37f57;
    border: 2px solid #2a1627;
    outline: 3px solid #fff;
    border-radius: 5px;
    
    background-image: url('../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.image {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    width: 20px;
    height: 20px;
    overflow: hidden;
}
.image img {
    width: 20px;
}

</style>