<template>
    <div class="tap_page">
        <details_vue/>

        <div class="taptap"  @touchstart="touch($event)">
            <div class="text-shadow">
                Tap Tap Tap <br/>
                To Earn
            </div>
        </div>


        
        <!-- <div class="bottom-section"> -->
            <div class="nav-section left-nav">
                <div class="nav" @click="back()">
                    <div>
                        <back_icon :size="'25px'"/>
                        Back
                    </div>
                </div>
                <div class="nav" @click="show_boost = true">
                    <div>
                        <profile_icon :size="'25px'"/>
                        Boost
                    </div>
                </div>
            </div>

            <div class="nav-section">
                <div class="nav">
                    <div>
                        <speed_icon :size="'25px'"/>
                        +{{store.recharge_speed}}
                    </div>
                </div>
                <div class="nav">
                    <div>
                        <battery_icon :size="'25px'"/>
                        {{store.energy_balance}}
                    </div>
                </div>
                <div class="nav">
                    <div>
                        <tap_icon :size="'25px'"/>
                        +{{store.multitap}}
                    </div>
                </div>
            </div>
        <!-- </div> -->

        
        <div>
            <plus_one v-for="(data, i) in plus_data" :data="data" :key="data.date" 
                @close="remove_plus_one(i)"/>
        </div>

        <boost_vue v-if="show_boost" @close="show_boost = false"/>

    </div>
</template>

<script setup>

import details_vue from '@/components/details.vue';

import back_icon from '@/icons/back.vue';

import speed_icon from '@/icons/speed.vue';
import battery_icon from '@/icons/battery.vue';
import tap_icon from '@/icons/tap.vue';

import plus_one from '@/components/plus_one.vue'

import boost_vue from '@/components/popup/boost.vue';

import { useRouter } from 'vue-router';
import { ref } from 'vue';

import { store } from '@/store/store';

const router = useRouter()

const back = () => {
    router.go(-1)
}

const show_boost = ref(false)

const plus_data = ref([])

const remove_plus_one = () => {
    plus_data.value.shift()
}


const touch = (e) => {
    

    for (let touch of e.targetTouches) {
        tap(touch)
    }
    
}

const tap = (e) => {

    if (store.energy_balance < store.multitap) { return }

    plus_data.value.push({
        'x': e.clientX,
        'y': e.clientY,
        'date': (new Date()).getTime()
    })

    
    store.total_points += store.multitap
    store.points += store.multitap

    store.energy_balance -= store.multitap

    store.updates++

}

</script>

<style scoped>

.tap_page {
    
    height: 100vh;
    width: 100vw;

    color: white;

    position: relative;
    z-index: 1;
}


.taptap {
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 45px;
    line-height: 60px;
    font-weight: 900;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    transition: .5s;
}
.taptap:active {
    /* text-shadow: rgba(156, 124, 70, 0.9) 0px 0px 15px; */
    color: white;

    /* transform: scale(1.2); */
}


.bottom-section {
}
.nav-section {
    
    /* width: 100vw; */
    box-sizing: border-box;
    position: absolute;
    bottom: 50px;
    right: 0px;
    padding: 0px 20px;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    display: flex;
    flex-direction: column;
    gap: 20px;
}
.nav {
    width: 50px;
    height: 50px;
    font-weight: 700;

    background-color: #fff;
    border: 2px solid #333;
    box-shadow: 4px 4px 2px #333;
    outline: 3px solid #fff;
    fill: #321a02;
    color: #fff;
    text-align: center;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    
    background-image: url('../assets/wood.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.left-nav {
    right: unset;
    left: 0px;
}

</style>